import React, { useState } from 'react';
import RunInc_IMG from '../../gfx/Site_RunInc.jpg';
import Scuba_IMG from '../../gfx/Site_Scuba.jpg';
import Gods_IMG from '../../gfx/Site_Gods.jpg';
import Boston_IMG from '../../gfx/Site_Boston.jpg';
import Tech_IMG from '../../gfx/Site_Tech.jpg';
import Bakery_IMG from '../../gfx/Site_Bakery.jpg';



const Project = () => {

    const [projects] = useState([

        // Bageriet //
        {
            img: Bakery_IMG, title: 'Bageriet', img_alt: 'Bageriet frontpage', date: '19.03.2021', link: 'http://bageriet.kenneth-sorensen.dk/', github: "https://github.com/KHS-85/Bageriet-Frontend", used: 'React, Bootstrap 4, Mongo-DB', id: 12,
            comment: 'Bakery website where you can create new recipes, add ingredients and leave comments - which is all stored on a Mongo database'
        },

        // Ken Tech //
        {
            img: Tech_IMG, title: 'KenTech', img_alt: 'KenTech frontpage', date: '08.01.2021', link: 'https://kentech.kenneth-sorensen.dk/', github: "https://github.com/KHS-85/antsite", used: 'React, Ant Design, SCSS, Photoshop', id: 11,
            comment: 'Experimenting with Ant Design instead of Bootstrap. Custom Topnav with animation - view on mobile device or reduce size of browser window to see.'
        },

        // Boston Gaming //
        {
            img: Boston_IMG, title: 'Boston Gaming', img_alt: 'Boston Gaming frontpage', date: '27.11.2020', link: 'https://bostongaming.kenneth-sorensen.dk/', github: "https://github.com/KHS-85/bostonstatic", used: 'React, Bootstrap 4, SCSS, Photoshop, Node, Express, Mongo-DB', id: 10,
            comment: 'Practice for server-side programming. This is only the frontend but the backend can be viewed on my Github.'
        },


        // Diving Djursland //
        {
            img: "/divingdjursland.png", title: 'Diving Djursland', img_alt: 'Diving Djursland frontpage', date: '12.10.2020', link: 'http://divingdjursland.kenneth-sorensen.dk/', github: "https://github.com/KHS-85/divingdjursland", used: 'React, Bootstrap 4, SCSS, Strapi, OpenStreetMap, Leaflet, Photoshop', id: 9,
            comment: 'Diving site with different diving locations which the user can either see as a list or on an interactive map. The location content comes from from a database controlled by the content management system "Strapi".', comment2: 'Database currently unavailable.'
        },

        // KenShop //
        {
            img: "/webshop.png", title: "Kenneth's Webshop", img_alt: 'webshop frontpage', date: '21.08.2020', link: 'http://kenn490m2.web.videndjurs.dk/', github: "https://github.com/KHS-85/", used: 'Wordpress and Photoshop', id: 8,
            comment: 'My first project created entirely in Wordpress. The content is mostly meant as a joke and and is only there to demonstrate the functionality of the webshop'
        },

        // Tentrees //
        {
            img: "/tentrees.png", title: 'Tentrees', img_alt: 'tentrees frontpage', date: '30.06.2020', link: 'http://tentrees.kenneth-sorensen.dk/', github: "https://github.com/KHS-85/tentrees", used: 'React, SCSS', id: 8,
            comment: 'Tried to recreate the frontpage from clothing store; tentrees, using React, SCSS and my own topnav-menu'
        },

        // Møllerup Gods //
        {
            img: Gods_IMG, title: 'Møllerup Gods', img_alt: 'Møllerup Gods frontpage', date: '28.03.2020', link: 'http://mollerup.kenneth-sorensen.dk/', github: "https://github.com/KHS-85/moellerrup", used: 'React, Bootstrap 4, SCSS, Photoshop', id: 6,
            comment: 'More practice in Bootstrap 4 and React; specifically state which is used on the news section of the page'
        },

        // ScubaLiving //
        {
            img: Scuba_IMG, title: 'ScubaLiving', img_alt: 'Scuba Living frontpage', date: '23.03.2020', link: 'http://scubaliving.kenneth-sorensen.dk/', github: "https://github.com/KHS-85/scubaliving", used: 'React, Bootstrap 4, SCSS, Photoshop', id: 5,
            comment: 'More practice at making responsive sites which scale correctly on all devices. Also practiced making forms with HTML5 validation, which you can see on the login-page'
        },

        // RunInc //
        {
            img: RunInc_IMG, title: 'RunInc', img_alt: 'RunInc frontpage', date: '27.02.2020', link: 'http://runinc.kenneth-sorensen.dk/', github: "https://github.com/KHS-85/runinc/commits/ResponsiveTest", used: 'React, Bootstrap 4, SCSS, Photoshop', id: 4,
            comment: 'First website that I created with React and Bootstrap'
        },

    ]);

    return (

        <div className="Project-body">
            {projects.map(project => {
                return (

                    <article className="container">
                        <section key={project.id} className="row my-5 website-block">

                            <div className="col-12 col-sm-12 col-md-6 p-0">

                                {/* Website Image */}

                                <div className="">
                                    <img className="project-img img-fluid" src={project.img} alt={project.img_alt} />
                                </div>

                            </div>


                            {/* Website Content */}

                            <div className="col text-left">
                                <h3 className="pb-2 pt-2"><strong>{project.title}</strong></h3>
                                <p><strong>Comments:</strong> {project.comment} <span className="comment2">{project.comment2}</span></p>
                                <p><strong>Used:</strong> {project.used}</p>

                                <a href={project.link} target="_blank" rel="noopener noreferrer" className="btn btn-dark m-2">Visit Site</a>
                                <a href={project.github} target="_blank" rel="noopener noreferrer" className="btn btn-dark m-2">Source code on GitHub</a>

                            </div>

                        </section>

                    </article>
                )
            })}
        </div>
    )
}

export default Project

